import PropTypes from "prop-types";
import React, { useEffect } from "react";
import logo from "../../assets/img/logo.png";
import LocalizedLink from "../LocalizedLink";
import { FormattedMessage } from "react-intl";
import globe from "../../assets/icons/globe.svg";
import { Location } from "@reach/router";

const NavBar = ({ path, toggleNavbar, isActive }) => {
  function togglePathLanguage(path) {
    if (!path) {
      return path;
    }
    const isChinese = path && path.includes("/zh");

    const splitPath = path.split("/");
    if (isChinese) {
      splitPath[splitPath.indexOf("zh")] = "..";
    } else {
      splitPath[0] = "/zh";
    }
    return splitPath.join("/");
  }

  function getLocaleFromPath(path) {
    if (!path) {
      return "en";
    }
    const isChinese = path && path.includes("/zh");

    return isChinese ? "zh-CN" : "en";
  }

  useEffect(() => {
    document.documentElement.lang = getLocaleFromPath(path);
  });

  return (
    <Location>
      {({ location }) => {
        const path = location.pathname;
        return (
          <nav className="navbar is-fixed-top" aria-label="main navigation">
            <div className="navbar-brand">
              <LocalizedLink to="/" className="navbar-item">
                <img className="logo" src={logo} alt="Hong Fa Granite"></img>
              </LocalizedLink>
              <button
                className={`button navbar-burger ${
                  isActive ? "is-active" : ""
                }`}
                data-target="navMenu"
                onClick={toggleNavbar}
                aria-label="Open the menu"
              >
                <span />
                <span />
                <span />
              </button>
            </div>
            <div
              className={`navbar-menu ${isActive ? "is-active" : ""}`}
              id="navMenu"
            >
              <div className="navbar-end">
                <LocalizedLink
                  className="navbar-item"
                  to={togglePathLanguage(path)}
                >
                  <img className="icon" id="globeIcon" src={globe} alt="" />
                  <FormattedMessage id="switchLanguage" />
                </LocalizedLink>
                <LocalizedLink
                  className={`navbar-item ${
                    path === "/" || path === "/zh/" ? "selected-nav" : ""
                  }`}
                  to="/"
                >
                  <FormattedMessage id="Home" />
                </LocalizedLink>
                <LocalizedLink
                  className={`navbar-item ${
                    path.includes("about") ? "selected-nav" : ""
                  }`}
                  to="/about"
                >
                  <FormattedMessage id="About" />
                </LocalizedLink>
                <LocalizedLink
                  className={`navbar-item ${
                    path.includes("products") ? "selected-nav" : ""
                  }`}
                  to="/products"
                >
                  <FormattedMessage id="Products" />
                </LocalizedLink>
                <LocalizedLink
                  className={`navbar-item ${
                    path.includes("gallery") ? "selected-nav" : ""
                  }`}
                  to="/gallery"
                >
                  <FormattedMessage id="Gallery" />
                </LocalizedLink>
                <div
                  className={`navbar-item ${
                    path.includes("contact") ? "selected-nav" : ""
                  }`}
                >
                  <div className="field is-grouped">
                    <p className="control">
                      <LocalizedLink
                        className="button is-primary is-outlined"
                        to="/contact"
                      >
                        <FormattedMessage id="Contact us" />
                      </LocalizedLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        );
      }}
    </Location>
  );
};

NavBar.propTypes = {
  isActive: PropTypes.bool,
  toggleNavbar: PropTypes.any,
};

export default NavBar;
