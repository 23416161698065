import PropTypes from "prop-types";
import React, { Component } from "react";
import SEO from "../SEO";
import "../../assets/sass/styles.sass";
import config from "../../../config";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { IntlProvider } from "react-intl";

import en from "../../i18n/en.json";
import zh from "../../i18n/zh.json";
import { Banner } from "../Banner";

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/en");
  require("@formatjs/intl-pluralrules/dist/locale-data/zh");
}

const messages = { en, zh };

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      locale: (props.path && props.path.includes("/zh/") ? "zh" : "en") || "en",
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({ isActive: !this.state.isActive });
  }
  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.setState({ locale: this.props.path.includes("/zh/") ? "zh" : "en" });
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.state.locale}
        messages={messages[this.state.locale]}
      >
        <div id="layout-wrapper">
          <SEO title={config.siteTitle} description={config.siteDescription} />
          <NavBar
            path={this.props.path}
            isActive={this.state.isActive}
            toggleNavbar={() => this.toggleNavbar()}
          />
          <Banner />
          <div id="content-wrapper">{this.props.children}</div>
          {/* <Footer locale={this.state.locale} /> */}
        </div>
      </IntlProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string,
};

export default Layout;
