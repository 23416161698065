module.exports = {
  siteTitle: "Hong Fa Granite", // Site title.
  siteTitleAlt: "Hong Fa Granite - Leaders in marble and granite countertops", // Alternative site title for SEO.
  siteLogo: "/icons/icon.png", // Logo used for SEO and manifest.
  siteUrl: "https://hongfagranite.com", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: "Leaders in marble and granite countertops!", // Website description used for RSS feeds/meta description tag.
  siteFBAppID: "", // FB Application ID for using app insights
  googleTagManagerID: "GTM-WZ45X8Z", // GTM tracking ID.
  themeColor: "#00d1b2", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
  email: "hongfagranite@gmail.com",
  phone: "905-470-1388",
  cellphone: "16478858127",
  cellphoneDisplay: "647-885-8127",
  fax: "905-489-1161",
  address: "125 Denison St. Markham ON",
  postalCode: "L3R 1B5",
};
