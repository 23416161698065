import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Hours = ({ isInFooter }) => (
  <>
    <h3 className={`${isInFooter ? 'has-text-centered' : ''} title is-4`}>
      <FormattedMessage id="hours" />
    </h3>
    <table className={isInFooter ? '' : 'table'}>
      <tbody>
        <tr>
          <td>
            <FormattedMessage id="Monday" /> - <FormattedMessage id="Saturday" />
          </td>
          <td>9 AM - 6 PM</td>
        </tr>
        <tr>
          <td>
            <FormattedMessage id="Sunday" />
          </td>
          <td>
            <p>
              <FormattedMessage id="Closed" />
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

Hours.propTypes = {
  isInFooter: PropTypes.bool,
};

export default Hours;
