import PropTypes from "prop-types";
import React from "react";
import config from "../../config";
import email from "../assets/icons/email.svg";
import location from "../assets/icons/location.svg";
import phone from "../assets/icons/phone.svg";
import print from "../assets/icons/print.svg";
import WhatsApp from "../assets/icons/WhatsApp.svg";
import { FormattedMessage } from "react-intl";

const ContactInfo = ({ isInFooter, component }) => (
  <section className={`contact-info ${component === "home" ? "is-primary-light" : ""} ${component}`}>
    <div className="contact-info__item">
      <img className="icon" src={email} alt=""></img>
      <p className="contact-info__item__label"><FormattedMessage id="Email" /></p>
      <a href={`mailto:${config.email}`}>hongfagranite<wbr />@gmail.com</a>
    </div>
    <div className="contact-info__item">
      <img className="icon" src={phone} alt=""></img>
      <p className="contact-info__item__label"><FormattedMessage id="Phone" /></p>
      <a href={`tel:${config.phone}`}>{config.phone}</a>
    </div>
    <div className="contact-info__item">
      <img className="icon" src={WhatsApp} alt=""></img>
      <p className="contact-info__item__label">WhatsApp</p>
      <a
        href={`https://wa.me/${config.cellphone}?text=I would like to schedule an appointment with Hong Fa Granite.`}
      >
        {config.cellphoneDisplay}
      </a>
    </div>
    {component === "home" ? null : (
      <>
        <div className="contact-info__item">
          <img className="icon" src={print} alt=""></img>
          <p className="contact-info__item__label"><FormattedMessage id="Fax" /></p>
          {config.fax}
        </div>
        <div className="contact-info__item">
          <img className="icon" src={location} alt=""></img>
          <p className="contact-info__item__label"><FormattedMessage id="Address" /></p>
          <a
            href="https://www.google.com/maps/place/Hong+Fa+Granite/@43.8209817,-79.3543673,17z/data=!4m5!3m4!1s0x89d4d366d8ff3bd5:0x90ed649693bdca4a!8m2!3d43.8209817!4d-79.3543674"
            rel="noopener noreferrer"
            target="_blank"
          >
            {config.address}
          </a>
        </div>
      </>
    )}
  </section>
);

ContactInfo.propTypes = {
  isInFooter: PropTypes.bool,
};

export default ContactInfo;
