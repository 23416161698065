import React from "react";
import useLocalStorageState from "use-local-storage-state";
import close from "../assets/icons/close.svg";
import LocalizedLink from "./LocalizedLink";
import { RichMessage } from "./RichMessage";

export function Banner() {
  const [isVisible, setIsVisible] = useLocalStorageState("isBannerVisible", {
    defaultValue: true,
  });

  if (!isVisible) {
    return null;
  }
  return (
    <article className="banner box">
      <div>
        <p style={{ fontWeight: 600, fontSize: "1.5rem" }}>
          <RichMessage id="banner1" />
        </p>
        <p style={{ paddingBottom: "0.5rem" }}>
          <RichMessage id="banner2" />
        </p>
        <LocalizedLink className="button is-primary" to="/contact">
          <button className="button is-primary bolded-button">
            <RichMessage id="banner_button" />
          </button>
        </LocalizedLink>
      </div>
      <button
        className="close-banner-button"
        onClick={() => {
          setIsVisible(false);
        }}
      >
        <img className="icon" src={close} alt=""></img>
      </button>
    </article>
  );
}
