import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import { injectIntl } from "react-intl";

import locales from "../constants/locales";

const LocalizedLink = ({ to, intl: { locale }, ...props }) => {
  const path = locales[locale].default ? to : `/${locale}${to}`;
  return <Link {...props} to={path} />;
};

LocalizedLink.propTypes = {
  to: PropTypes.string,
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

export default injectIntl(LocalizedLink);
