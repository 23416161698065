import React from "react";
import { FormattedMessage } from "react-intl";
import LocalizedLink from "./LocalizedLink";

const localizedLinkTag = (to) => {
  return (...chunks) => {
    return <LocalizedLink to={to}>{chunks}</LocalizedLink>;
  };
};

export const RICH_TAGS = {
  "maps-link": (...chunks) => {
    return (
      <a href="https://www.google.com/maps/place/Hong+Fa+Granite/@43.8209817,-79.3543673,17z/data=!4m6!3m5!1s0x89d4d366d8ff3bd5:0x90ed649693bdca4a!8m2!3d43.8206548!4d-79.354539!16s%2Fg%2F1tgxk0t1?entry=ttu">
        {chunks}
      </a>
    );
  },
  "about-link": localizedLinkTag("/about"),
  "contact-link": localizedLinkTag("/contact"),
  "gallery-link": localizedLinkTag("/gallery"),
  "products-link": localizedLinkTag("/products"),
};

export const RichMessage = ({ id, values }) => {
  return <FormattedMessage id={id} values={{ ...RICH_TAGS, ...values }} />;
};
