import PropTypes from "prop-types";
import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const storeLocation = {
  lat: 43.821005,
  lng: -79.354324,
};

const MyCustomMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap defaultZoom={13} defaultCenter={storeLocation}>
      <Marker position={storeLocation} />
    </GoogleMap>
  ))
);

const Map = (props) => (
  <MyCustomMap
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places&callback=Function.prototype`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: props.height || "400px" }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);

Map.propTypes = {
  height: PropTypes.string,
};

export default Map;
